import ajax from "@/utils/request/ajax";
import { SpreadSheetData, AuthData } from "@/types/api";

const request = ajax;

const spreadSheetData = (authData: AuthData) =>
    request.post("/data", authData) as Promise<SpreadSheetData>;
// const currencyChange = () => request.get("") as Promise<>;

export { spreadSheetData };
