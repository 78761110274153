import React from "react";
import "./App.css";
import Main from "@/router";

const App: React.FC = () => {
    return (
        <>
            <Main />
        </>
    );
};

export default App;
