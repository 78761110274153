import React from "react";
import { NavBar } from "antd-mobile";

export const TopBarLayout: React.FC = () => {
    return (
        <>
            <NavBar backArrow={false}></NavBar>
        </>
    );
};

export default TopBarLayout;
