import React, { useState } from "react";
import {
    Button,
    Picker,
    Input,
    AutoCenter,
    Form,
    Selector,
    Space
} from "antd-mobile";
import { AuthData, SpreadSheetData } from "@/types/api";
import { spreadSheetData } from "@/api/api";
import { DataTable } from "./components/ComponentDataTable";

const Home: React.FC = (props: any) => {
    const [options] = useState([
        { label: "K", value: 206 },
        { label: "S", value: 57 }
    ]);

    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [selectedShopId, setSelectedShopId] = useState(206);
    const [data, setData] = useState<SpreadSheetData>([]);

    const outputExpense = () => {
        if (data.length !== 0) {
            if (data[0].expense === undefined) {
                return (
                    <>
                        <h3>Total Expense: 0</h3>
                        <h3>Net Pay: {data[0].totalAmount}</h3>
                    </>
                );
            } else {
                return (
                    <>
                        <h3>
                            {data.length
                                ? "Total Expense " + data[0].expense
                                : ""}
                        </h3>
                        <h3>
                            Net Pay: {data[0].totalAmount - data[0].expense}
                        </h3>
                    </>
                );
            }
        }
    };

    const handleSelect = (value: number[]) => {
        setSelectedShopId(value[0]);
    };

    const handleSubmit = () => {
        setIsLoading(true);

        const authData: AuthData = {
            username: username,
            password: password,
            selectedShopId: selectedShopId
        };

        setData([]);

        spreadSheetData(authData)
            .then((res: SpreadSheetData) => {
                console.log(res);
                setData(res);
                setIsLoading(false);
            })
            .catch((err: Error) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    return (
        <>
            <AutoCenter></AutoCenter>
            {/* <h3>Select Shop</h3> */}
            <Selector
                columns={2}
                options={options}
                defaultValue={[206]}
                multiple={false}
                onChange={value => handleSelect(value)}
            />
            <Form
                layout="horizontal"
                footer={
                    <Button
                        loading={isLoading}
                        block
                        onClick={() => handleSubmit()}
                        color="primary"
                    >
                        Get Data
                    </Button>
                }
            >
                <Form.Item
                    name="username"
                    label="Username"
                    rules={[
                        {
                            required: true,
                            message: "Username cannot be empty!"
                        }
                    ]}
                >
                    <Input
                        placeholder="Username"
                        value={username}
                        onChange={value => setUsername(value)}
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                        {
                            required: true,
                            message: "Password cannot be empty!"
                        }
                    ]}
                >
                    <Input
                        placeholder="Password"
                        value={password}
                        onChange={value => setPassword(value)}
                    />
                </Form.Item>

                {/* <Button color="primary" block onClick={() => handleSubmit()}>Get Data</Button> */}
            </Form>
            <h3>Result</h3>
            <h3>{data.length ? "Welcome back " + data[0].name : ""}</h3>
            <h3>{data.length ? "Num of Jobs " + data[0].numOfJobs : ""}</h3>
            <h3>{data.length ? "Total Payment " + data[0].totalAmount : ""}</h3>
            {outputExpense()}

            <DataTable data={data} />
        </>
    );
};

export default Home;
