import React from "react";
import { SpreadSheetData } from "@/types/api";

interface DataTableProps {
    data: SpreadSheetData;
}

export const DataTable: React.FC<DataTableProps> = ({ data }) => {
    return (
        <>
            <table>
                <thead>
                    <tr>
                        <th>Duration</th>
                        <th>Start</th>
                        <th>Finish</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item: any) => (
                        <tr key={item.name}>
                            <td>{item.duration}</td>
                            <td>{item.timeStart}</td>
                            <td>{item.timeFinish}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default DataTable;
